import React from 'react';
import { Select } from 'theme-ui';
import {
  LocationType,
} from 'Generated/graphql';

type LocationTypeSelectProps = {
  onSelect?: (t: LocationType) => void;
  initialValue?: string | undefined;
  value: LocationType;
  hideOptions?: LocationType[];
}

const LocationTypePretty: Record<LocationType, string> = {
  [LocationType.Partner]: 'Condo/Apartment',
  [LocationType.Business]: 'Corporate Office',
  [LocationType.ServiceProvider]: 'Service Provider',
  [LocationType.Individual]: 'Residential',
  [LocationType.IndividualWork]: 'Work',
  [LocationType.IndividualIndeterminate]: 'Residential',
  [LocationType.ServiceProviderRetail]: 'Retail',
};

const LocationTypeSelect: React.FC<LocationTypeSelectProps> = ({
  value,
  onSelect,
  initialValue,
  hideOptions,
}) => {
  const handleSelect = (t: string) => {
    onSelect && onSelect(t as LocationType);
  };

  return (
    <>
      <Select
        value={initialValue || value}
        sx={{
          pr: '1.5rem',
        }}
        onChange={e => {
          const t = e.currentTarget.value;
          handleSelect(t);
        }}
      >
        {Boolean(value) || <option value=""></option>}
        {Object.entries(LocationType).map(
          ([, v]) => hideOptions?.includes(v) || (
            <option value={v} key={v}>
              {LocationTypePretty[v]}
            </option>
          )
        )}
      </Select>
    </>
  );
};

export default LocationTypeSelect;
