import { Field, FieldProps } from 'formik';
import { UpdateLocationInput } from 'Generated/graphql';
import { Input } from 'theme-ui';

type UidFieldProps = {
  name: string;
}

const UidField: React.FC<UidFieldProps> = ({
  name,
}) => {
  const fieldFn =
    (
      { field }: FieldProps<UpdateLocationInput['uid']>,
    ) => {
      return (
        <Input
          type="hidden"
          value={field.value}
        />
      );
    };

  return (
    <>
      <Field
        label=""
        name={name}
      >
        {fieldFn}
      </Field>
    </>
  );
};

export default UidField;

