import { Field, FieldProps } from 'formik';
import React from 'react';
import { CreateLocationInput } from 'Generated/graphql';
import GooglePlacesAutocomplete from
  'Common/components/GooglePlacesAutocomplete';

type PlaceIdFieldProps = {
  name: string;
  defaultAddress?: string;
}

const PlaceIdField: React.FC<PlaceIdFieldProps> = ({
  name,
  defaultAddress,
}) => {

  const fieldFn =
    ({
      form,
      meta,
    }: FieldProps<CreateLocationInput['placeId']>) => {
      return (
        <div
          className="flex flex-col w-full"
        >
          <label>
            <GooglePlacesAutocomplete
              data-testid="locationForm_placeIdInput"
              defaultAddress={defaultAddress}
              placeholder='Address'
              onSelect={p => {
                if (p) {
                  form.setFieldValue(name, p.place_id);
                }
              }}
            />
          </label>
          {meta.error && (
            <small className="error">
              Please enter the address for this location and select the location
              from the list below.
            </small>
          )}
        </div>
      );
    };

  return (
    <>
      <div
        className="flex sm:flex-col md:flex-row w-full justify-evenly"
      >
        <Field
          label=""
          name={name}
          style={{
            width: '100%',
          }}
          validate={(v: string) => !v ?
            'Please enter the address for this location and select the location from the list below.' :
            undefined}
        >
          {fieldFn}
        </Field>
      </div>
    </>
  );
};

export default PlaceIdField;

