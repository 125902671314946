import PhoneInput, { Props as PhoneInputProps } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

type PhoneNumberInputProps = PhoneInputProps<Record<string, unknown>>

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = (props) => {
  return (
    <PhoneInput
      {...props}
    />
  );
};

export default PhoneNumberInput;
