import { Theme, ThemeUIStyleObject } from 'theme-ui';
import MimoblTheme from 'Common/theme.json';
import ThemePeazy from 'Common/themePeazy.json';

const themeJSON = MimoblTheme;

export const primaryFont = '"Proxima Nova", sans-serif';
export const secondaryFont = '"Proxima Nova", sans-serif';
export const headerFont = '"gelica-light", serif';

const borderRadius = '.375rem';
const buttonBg = ThemePeazy.colors.orange;
const buttonBgHover = ThemePeazy.colors.lightOrange;
const altBackground = ThemePeazy.colors.lightBlue;

const cardBase: ThemeUIStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  wordWrap: 'break-word',
  backgroundColor: '#fff',
  backgroundClip: 'border-box',
  borderRadius: '4px'
};

const body1: ThemeUIStyleObject = {
  color: ThemePeazy.colors.black,
  fontFamily: primaryFont,
  fontWeight: '400',
  fontSize: ['14px', '16px'],
  lineHeight: '140%',
};

const body2: ThemeUIStyleObject = {
  color: ThemePeazy.colors.black,
  fontFamily: primaryFont,
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: ['15px', '19px'],
};

const link1: ThemeUIStyleObject = {
  color: '#1E7120',
  fontFamily: primaryFont,
  fontWeight: '500',
  fontSize: ['14px', '16px'],
  lineHeight: ['19px', '21px'],
};

const mainFormButton: ThemeUIStyleObject = {
  p: ['6px 0', '10px 25px'],
  fontSize: ['12px', '14px'],
  lineHeight: ['15px', '17px'],
  fontWeight: '600',
  background: buttonBg,
  borderRadius: '9999px',
  color: themeJSON.colors.Black,
  width: '100%',
  maxWidth: '516px',
  cursor: 'pointer',
  ':focus,:hover': {
    background: buttonBgHover,
    color: themeJSON.colors.Black
  },
};

const subtitle1: ThemeUIStyleObject = {
  color: '#6F6D62',
  fontFamily: primaryFont,
  fontWeight: '600',
  fontSize: ['12px', '14px'],
  lineHeight: ['15px', '17px'],
  letterSpacing: ['0.1em', '0.12em'],
  textTransform: 'uppercase',
};

const header1: ThemeUIStyleObject = {
  fontFamily: headerFont,
  fontStyle: 'normal',
  fontWeight: '300',
  fontSize: ['1.375rem', '2rem'],
  color: themeJSON.colors.Black,
  lineHeight: '1.2',
  letterSpacing: '0em',
  display: 'block',
};
const header2: ThemeUIStyleObject = {
  fontFamily: primaryFont,
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: ['16px', '20px'],
  lineHeight: ['19px', '24px'],
  color: '#000000',
};

const underlinedButton: ThemeUIStyleObject = {
  fontFamily: secondaryFont,
  textDecoration: 'underline',
  fontSize: '0.875rem',
  fontWeight: '500',
  background: 'transparent',
  boxShadow: 'none',
  cursor: 'pointer',
  ':focus,:hover': {
    boxShadow: 'elevated',
    transform: 'scale(1.0625)',
  },
};

export const buttonBase: ThemeUIStyleObject = {
  cursor: 'pointer',
  transition: 'box-shadow .25s ease,transform .25s ease',
  ':hover': {
    opacity: '0.7',
    transform: 'translate3d(0,-3px,0)',
  },
  '&[disabled]': {
    opacity: '0.6',
  },
};

export const cardButtonBase: ThemeUIStyleObject = {
  ...buttonBase,
  borderRadius,
  p: '.5rem 1rem',
  ':hover': {
    ...buttonBase[':hover'],
    backgroundColor: 'rgba(104, 91, 199, 0.1)',
  },
};

const buttonBaseWithShadow: ThemeUIStyleObject = {
  ...buttonBase,
  boxShadow:
    '0 1rem 2.5rem rgba(22, 28, 45, .1),0 .5rem 1rem -.75rem rgba(22, 28, 45, .1)',
};

const cardButtonBaseV2: ThemeUIStyleObject = {
  fontFamily: primaryFont,
  borderRadius: '2rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'card',
  fontWeight: '600',
  fontSize: '0.875rem',
  py: ['0.25rem', '0.25rem', '0.5rem'],
  letterSpacing: 'headline',
  WebkitTapHighlightColor: 'transparent',
  color: ThemePeazy.colors.black,
  border: 'transparent 2px solid',
  whiteSpace: 'nowrap',
  transition: 'transform .125s ease-in-out, box-shadow .125s ease-in-out',
  cursor: 'pointer',
  ':focus,:hover': {
    boxShadow: 'elevated',
  },
};

export const inputBase: ThemeUIStyleObject = {
  borderRadius,
  border: `1px solid ${themeJSON.colors.BorderGray}`,
  '&:focus': {
    outline: `2px solid ${themeJSON.colors.black}`,
  }
};

const inputBaseV2: ThemeUIStyleObject = {
  borderRadius: '0.25rem',
  border: '1px solid #d9d9d9',
  backgroundColor: '#FFF',
  '&:focus': {
    outline: '2px solid #000',
  }
};

const textBasePrimitive: ThemeUIStyleObject = {
  color: themeJSON.colors.Black,
  fontFamily: primaryFont,
  lineHeight: '1.3',
  letterSpacing: '0em',
  display: 'block',
};

const textBase: ThemeUIStyleObject = {
  ...textBasePrimitive,
  fontWeight: '400',
};

const tabLinkBase: ThemeUIStyleObject = {
  ...textBasePrimitive,
  fontSize: ['0.75rem', '1rem'],
  p: ['0.5rem 1rem', '1rem 2rem'],
  fontWeight: '600',
  borderRadius: ['0.25rem 0.25rem 0 0'],
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  borderBottom: '5px solid transparent',
  cursor: 'pointer',
  ':hover': {
    background: ThemePeazy.colors.ghostWhite,
    borderBottomColor: ThemePeazy.colors.lightOrange
  }
};

const theme: Theme = {
  config: {
    useColorSchemeMediaQuery: false,
  },
  breakpoints: ['600px', '900px', '1400px'],
  fonts: {
    heading: headerFont,
    body: secondaryFont,
    monospace: 'Menlo, monospace',
  },
  colors: {
    ...themeJSON.colors,
    text: '#000',
    background: 'transparent',
    primary: ThemePeazy.colors.darkBlue,
    secondary: ThemePeazy.colors.orange,
    highlight: ThemePeazy.colors.darkBlue,
    blue: ThemePeazy.colors.darkBlue,
  },
  links: {
    mainFormButton,
    nav: {
      primary: {
        ':hover': {
          fontWeight: [400, 400, 700],
          textDecoration: 'unset',
          color: [
            ThemePeazy.colors.lightOrange,
            ThemePeazy.colors.lightOrange,
            ThemePeazy.colors.black,
          ],
        },
        textTransform: ['none', 'none', 'uppercase'],
        color: [
          ThemePeazy.colors.darkBlue,
          ThemePeazy.colors.darkBlue,
          ThemePeazy.colors.black,
        ],
        fontSize: ['2rem', '2rem', '1rem'],
        fontWeight: 400,
        fontFamily: [
          headerFont,
          headerFont,
          primaryFont
        ],
      },
      primaryActive: {
        ':hover': {
          fontWeight: [400, 400, 700],
          textDecoration: 'unset',
          color: [
            ThemePeazy.colors.lightOrange,
            ThemePeazy.colors.lightOrange,
            ThemePeazy.colors.black,
          ],
        },
        textTransform: ['none', 'none', 'uppercase'],
        color: [
          ThemePeazy.colors.orange,
          ThemePeazy.colors.orange,
          ThemePeazy.colors.black,
        ],
        fontSize: ['2rem', '2rem', '1rem'],
        fontWeight: [400, 400, 700],
        fontFamily: [
          headerFont,
          headerFont,
          primaryFont
        ],
      },
      secondary: {
        ':hover': {
          fontWeight: [400, 400, 700],
          textDecoration: 'unset',
          color: [
            ThemePeazy.colors.lightOrange,
            ThemePeazy.colors.lightOrange,
            ThemePeazy.colors.black,
          ],
        },
        color: [
          ThemePeazy.colors.darkBlue,
          ThemePeazy.colors.darkBlue,
          ThemePeazy.colors.black,
        ],
        display: 'block',
        width: '100%',
        textAlign: ['left', 'left', 'right'],
        pr: ['0rem', '0rem', '1.45rem'],
        fontSize: ['2rem', '2rem', '1rem'],
        fontWeight: 400,
        fontFamily: [
          headerFont,
          headerFont,
          primaryFont
        ],
        textDecoration: 'none',
      },
      secondaryActive: {
        ':hover': {
          fontWeight: [400, 400, 700],
          textDecoration: 'unset',
          color: [
            ThemePeazy.colors.orange,
            ThemePeazy.colors.orange,
            ThemePeazy.colors.black,
          ],
        },
        color: [
          ThemePeazy.colors.orange,
          ThemePeazy.colors.orange,
          ThemePeazy.colors.black,
        ],
        display: 'block',
        width: '100%',
        textAlign: ['left', 'left', 'center'],
        fontSize: ['2rem', '2rem', '1rem'],
        fontWeight: 400,
        fontFamily: [
          headerFont,
          headerFont,
          primaryFont
        ],
      },
    },
    activeNav: {
      ':hover': {
        textDecoration: ['underline', 'underline', 'unset'],
        color: ['primary', 'white', 'white'],
      },
      color: ThemePeazy.colors.orange,
      fontSize: ['2rem', '2rem', '1rem'],
      fontWeight: '700',
      fontFamily: primaryFont,
    },
    defaultNav: {
      ':hover': {
        fontWeight: ['bold', 'bold', 'unset'],
        textDecoration: ['underline', 'underline', 'unset'],
        color: ['primary', 'white', 'white'],
      },
      color: ThemePeazy.colors.black,
      fontSize: ['1.5rem', '1.5rem', '1rem'],
      fontWeight: '400',
      fontFamily: primaryFont,
    },
    base: {
      color: ThemePeazy.colors.darkBlue,
      cursor: 'pointer',
      ':hover': {
        textDecoration: 'underline',
      },
    },
    boldUnderlined: {
      color: ThemePeazy.colors.darkBlue,
      fontWeight: 'bold',
      textDecoration: 'underline',
      ':hover': {
        textShadow: '0px 0px 1px rgba(0, 0, 0, 0.25)',
      }
    },
    tabLink: {
      ...tabLinkBase
    },
    tabLinkActive: {
      ...tabLinkBase,
      background: ThemePeazy.colors.newspaper,
      borderBottom: '5px solid',
      borderBottomColor: ThemePeazy.colors.orange,
      ':hover': {
        background: ThemePeazy.colors.newspaper,
      }
    },
    grayUnderlined: {
      color: '#595959',
      textDecoration: 'underline',
    },
  },
  buttons: {
    primary: {
      ...buttonBaseWithShadow,
      color: ThemePeazy.colors.black,
      bg: ThemePeazy.colors.orange,
      p: '0.5rem 3rem',
      fontSize: '1rem',
      fontWeight: '600',
      borderRadius: '2rem',
      ':hover': {
        backgroundColor: ThemePeazy.colors.lightOrange
      }
    },
    secondary: {
      ...buttonBaseWithShadow,
      color: ThemePeazy.colors.black,
      bg: 'white',
      borderRadius: '2rem',
      p: ['0.5rem 1rem', '0.5rem 1rem', '0.75rem 1.5rem'],
      fontSize: ['12px', '14px'],
      lineHeight: ['15px', '17px'],
      fontWeight: '600',
      ':hover': {
        backgroundColor: ThemePeazy.colors.lightBlue
      }
    },
    roundedSecondary: {
      ...buttonBaseWithShadow,
      color: ThemePeazy.colors.black,
      bg: 'white',
      borderRadius: '99rem',
      aspectRatio: '1/1',
      p: '0.25rem 0.675rem',
      fontSize: ['0.75rem', '1rem'],
      lineHeight: ['15px', '17px'],
      fontWeight: '600',
      ':hover': {
        backgroundColor: ThemePeazy.colors.lightBlue
      }
    },
    highlight: {
      ...buttonBaseWithShadow,
      background: '#00C7B1',
      borderRadius: '2rem',
    },
    default: {
      ...buttonBaseWithShadow,
      borderRadius: '2rem',
    },
    mainFormButton,
    underlined: {
      ...underlinedButton,
      color: ThemePeazy.colors.darkBlue
    },
    plainText: {
      ...underlinedButton,
      p: 0,
      color: ThemePeazy.colors.darkBlue,
      textDecoration: 'none',
      ':focus,:hover': {
        textDecoration: 'underline',
      },
    },
    plainHeadingUppercase: {
      fontFamily: secondaryFont,
      color: ThemePeazy.colors.black,
      fontSize: ['0.8rem', '0.9rem'],
      lineHeight: ['0.9rem', '1rem'],
      fontWeight: 'semibold',
      letterSpacing: '0.15rem',
      textTransform: 'uppercase',
      bg: 'none',
      border: 'none'
    },
    link: {
      ...buttonBaseWithShadow,
      borderRadius: 0,
      border: 'none',
      boxShadow: 'none',
      background: 'none',
      color: 'secondary',
      ':hover': {
        ...buttonBaseWithShadow[':hover'],
        transform: 'none',
      },
    },
    cardButton: {
      ...cardButtonBaseV2,
      background: buttonBg,
      ':focus,:hover': {
        background: buttonBgHover,
      },
    },
    widePrimaryButton: {
      ...mainFormButton,
      p: '10px 25px',
      width: '334px',
      display: 'flex',
      justifyContent: 'center',
    },
    danger: {
      ...cardButtonBaseV2,
      bg: 'red',
      ':focus,:hover': {
        bg: buttonBgHover,
        border: '1px solid red'
      },
    },
    cardButtonSecondaryV2: {
      ...cardButtonBaseV2,
      border: `1px solid ${themeJSON.colors.Beige}`,
      background: themeJSON.colors.LightBeige,
      ':focus,:hover': {
        bg: themeJSON.colors.Beige
      },
    },
    timePill: {
      fontFamily: primaryFont,
      border: '1px solid #E7E7E7',
      background: 'transparent',
      color: themeJSON.colors.black,
      width: '91px',
      height: '29px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '25px',
      fontSize: '12px',
      p: 0,
      lineHeight: '12px',
      cursor: 'pointer',
    },
    timeOfDayButton: {
      color: '#6F6D62',
      borderColor: '#6F6D62',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '29px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 'min(33%, 132px)',
      width: ['33%', '33%', '100%'],
      height: ['28px', '28px', '32px'],
      fontFamily: primaryFont,
      fontWeight: '700',
      fontSize: ['11px', '11px', '14px'],
      lineHeight: ['13px', '13px', '17px'],
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
    disabled: {
      ...buttonBaseWithShadow,
      opacity: 0.6,
      borderRadius: '2rem',
    },
  },
  grids: {
    layoutContainer: {
      px: ['1rem', '0'],
      gridTemplateColumns: [
        'repeat(6, minmax(0, 1fr))',
        'repeat(14, minmax(0, 1fr))',
      ],
      gap: '20px',
      alignItems: 'start',
      width: '100%',
      maxWidth: '1440px',
      mx: 'auto'
    },
    fullWidth: {
      gridColumn: ['span 6 / span 6', 'span 12 / span 12', 'span 12 / span 12'],
      gridColumnStart: ['1', '2', '2'],
    },
    centreSixColumn: {
      gridColumn: ['span 6 / span 6', 'span 12 / span 12', 'span 6 / span 6'],
      gridColumnStart: ['1', 2, '5'],
    },
    leftSixColumn: {
      gridColumn: ['span 6 / span 6', 'span 12 / span 12', 'span 6 / span 6'],
      gridColumnStart: ['1', '2', '2'],
    },
    leftFiveColumn: {
      gridColumn: ['span 6 / span 6', 'span 12 / span 12', 'span 5 / span 5'],
      gridColumnStart: ['1', '2', '3'],
    },
    leftFourColumn: {
      gridColumn: ['span 6 / span 6', 'span 4 / span 4'],
      gridColumnStart: ['1', '2'],
    },
    rightFiveColumn: {
      gridColumn: ['span 6 / span 6', 'span 12 / span 12', 'span 5 / span 5'],
      gridColumnStart: ['1', 2, '9'],
    },
    rightEightColumn: {
      gridColumn: ['span 6 / span 6', 'span 8 / span 8'],
      gridColumnStart: ['1', '6'],
    },
  },
  cards: {
    concierge: {
      tip: {
        backgroundColor: 'transparent',
        cursor: 'pointer',
        py: [17, 17, 9.5],
        borderRadius: 5,
        border: `1.5px solid ${ThemePeazy.colors.lightBlue}`
      },
      activeTip: {
        py: [17, 17, 9.5],
        backgroundColor: ThemePeazy.colors.darkBlue,
        cursor: 'pointer',
        borderRadius: 5,
        border: `1.5px solid ${ThemePeazy.colors.darkBlue}`
      }
    },
    primary: {
      ...cardBase,
      padding: '2rem',
    },
    noPadding: {
      ...cardBase,
      boxShadow: '0px 1px 21px -2px rgba(0, 0, 0, 0.07)',
    },
    shadow: {
      ...cardBase,
      boxShadow: '0px 1px 21px -2px rgba(0, 0, 0, 0.07)',
      p: '25px',
    },
    noShadow: cardBase,
    bookingCard: {
      ...cardBase,
      position: 'relative',
      p: '0',
      cursor: 'pointer',
      fontFamily: secondaryFont,
      minWidth: '25%',
    },
    selectedServiceCard: {
      background: altBackground,
      p: '14px 16px',
      borderRadius: '4px',
      color: ThemePeazy.colors.darkBlue,
    },
    highlightCard: {
      background: altBackground,
      p: '25px',
      fontSize: '14px',
    },
    paleHighlightCard: {
      background: ThemePeazy.colors.paleBlue,
      p: 0,
      borderRadius: '0.5rem',
    },
    soapStone: {
      ...cardBase,
      padding: '2rem',
      backgroundColor: ThemePeazy.colors.soapStone,
    }
  },
  text: {
    concierge: {
      h1: {
        color: themeJSON.colors.Black,
        fontWeight: '400',
        fontFamily: headerFont,
        fontSize: ['1.375rem', '2rem'],
        lineHeight: '1.2',
        letterSpacing: '0em',
        display: 'block',
      },
      h2: {
        color: themeJSON.colors.Black,
        fontWeight: '400',
        fontFamily: primaryFont,
        fontSize: ['1rem', '1.25rem'],
        lineHeight: '1.2',
        letterSpacing: '0em',
        display: 'block',
      },
      subtitle1: {
        ...subtitle1,
      },
      body1: {
        ...textBase,
      },
      body2: {
        ...textBase,
      },
      body2Bold: {
        ...textBase,
        fontWeight: '700',
      },
      optionCardHeader: {
        color: themeJSON.colors.offWhite,
        fontFamily: primaryFont,
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: 1.7,
      },
      marketPlaceTitle: {
        fontFamily: primaryFont,
        p: ['0.75rem', '1rem 1.5rem'],
        fontSize: ['14px', '18px'],
        lineHeight: ['19px', '22px'],
        color: '#000000',
        background: '#FFFFFF',
        alignItems: 'center',
        display: 'flex',
        flexGrow: '1'
      },
      link1: {
        color: themeJSON.colors.DarkGreen,
        fontWeight: '400',
        fontFamily: primaryFont,
        fontSize: ['0.875rem', '1rem'],
        lineHeight: '1.3',
        letterSpacing: '0em',
        textAlign: ['center', 'left', 'left'],
        display: 'block',
      },
      dashboardSectionHeading: {
        fontFamily: secondaryFont,
        color: MimoblTheme.colors.headingGray,
        fontSize: ['0.8rem', '0.9rem'],
        lineHeight: ['0.9rem', '1rem'],
        fontWeight: 'semibold',
        letterSpacing: '0.15rem',
        textTransform: 'uppercase'
      },
      bookingCardTitle: {
        fontFamily: secondaryFont,
        fontSize: '0.75rem',
        fontHeight: 600,
        lineHeight: '1rem',
        letterSpacing: '0.15rem',
        textTransform: 'uppercase',
      }
    },
    heading: {
      h1: {
        color: themeJSON.colors.Black,
        fontWeight: '400',
        fontFamily: headerFont,
        fontSize: ['1.375rem', '2rem'],
        lineHeight: '1.2',
        letterSpacing: '0em',
        display: 'block',
      },
      h2: {
        color: themeJSON.colors.Black,
        fontWeight: '400',
        fontFamily: primaryFont,
        fontSize: ['1rem', '1.25rem'],
        lineHeight: '1.2',
        letterSpacing: '0em',
        display: 'block',
      },
      h3: {
        color: themeJSON.colors.black,
        fontFamily: primaryFont,
        lineHeight: '1.4',
        fontWeight: '600',
        fontSize: ['0.9rem', '1.1rem', '1.2rem'],
        letterSpacing: '0em',
        display: 'block',
      },
      dashboardSectionHeading: {
        fontFamily: secondaryFont,
        color: MimoblTheme.colors.headingGray,
        fontSize: ['0.8rem', '0.9rem'],
        lineHeight: ['0.9rem', '1rem'],
        fontWeight: 'semibold',
        letterSpacing: '0.15rem',
        textTransform: 'uppercase'
      },
    },
    h2: {
      color: themeJSON.colors.black,
      fontWeight: '600',
      fontFamily: primaryFont,
      fontSize: ['1.1rem', '1.2rem'],
      lineHeight: '1.5rem',
      letterSpacing: '0em',
      display: 'block',
    },
    h3: {
      color: themeJSON.colors.black,
      fontFamily: primaryFont,
      lineHeight: '1.4',
      fontWeight: '600',
      fontSize: ['0.9rem', '1.1rem', '1.2rem'],
      letterSpacing: '0em',
      display: 'block',
    },
    default: textBasePrimitive,
    block: {
      ...textBase,
      display: 'block',
    },
    primary: {
      color: themeJSON.colors.primary,
      fontFamily: primaryFont,
      fontWeight: '400',
      fontSize: '18px',
    },
    secondary: {
      color: '#000',
      fontFamily: secondaryFont,
      fontWeight: '400',
      fontSize: ['0.8rem', '0.8rem', '1rem'],
      letterSpacing: '0em',
      lineHeight: '1rem',
    },
    body1: {
      ...body1,
    },
    body2: {
      ...textBase
    },
    bodySm: {
      ...textBase,
      fontSize: '0.875rem',
    },
    bodyXs: {
      ...textBase,
      fontSize: '0.75rem',
    },
    body2Big: {
      ...textBase,
      fontSize: ['1.25rem'],
    },
    body2Bold: {
      ...textBase,
      fontWeight: '700',
    },
    body2Base: {
      ...textBase,
      fontSize: ['0.875rem', '1rem']
    },
    body3: {
      ...textBase,
      fontSize: '1rem',
    },
    subtitle: {
      ...textBase,
      fontSize: ['0.725rem', '0.725rem', '0.875rem'],
      fontFamily: secondaryFont,
      fontWeight: '400',
      lineHeight: '1rem',
    },
    bookingCardTitle: {
      color: MimoblTheme.colors.Black,
      fontFamily: secondaryFont,
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.2rem',
      letterSpacing: '0em',
      display: 'block',
    },
    bookingCardSubtitle: {
      color: MimoblTheme.colors.Black,
      fontFamily: secondaryFont,
      fontSize: '0.875rem',
      fontWeight: '700',
      lineHeight: '1.2rem',
      letterSpacing: '0em',
      display: 'block',
    },
    cardText: {
      fontFamily: secondaryFont,
      color: '#595959',
      fontWeight: '400',
      fontSize: ['0.8rem', '0.8rem', '0.9rem'],
      lineHeight: '1.1rem',
      letterSpacing: '0em',
      display: 'block',
    },
    optionCardHeader: {
      color: '#564AB3',
      fontFamily: primaryFont,
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: 1.7,
    },
    optionCardAttributes: {
      color: '#595959',
      fontFamily: secondaryFont,
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: 1.25,
    },
    paragraph: {
      ...textBase,
      mb: '1rem',
    },
    error: {
      ...textBase,
      color: 'red',
    },
    help: {
      ...textBase,
      color: 'gray',
    },
    white: {
      ...textBase,
      color: 'white',
    },
    highlightTitle: {
      display: 'flex',
      ...subtitle1,
    },
    highlightList: {
      display: 'list-item',
      listStyle: 'none',
      position: 'relative',
      ...body2,
      ':before': {
        content: '"·"',
        fontSize: '30px',
        position: 'absolute',
        left: '-14px',
        top: '-2px'
      }
    },
    header1: {
      ...header1,
    },
    header2: {
      ...header2,
    },
    subtitle1: {
      ...subtitle1,
    },
    link1: {
      ...link1,
    },
    navigationLocationText: {
      textOverflow: 'ellipsis',
      color: MimoblTheme.colors.Black,
      fontSize: [12, 12, '1rem'],
      fontFamily: secondaryFont,
      fontWeight: 600,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      paddingLeft: '0',
      textTransform: 'uppercase',
      letterSpacing: '0.1em'
    },
    uppercase: {
      fontFamily: secondaryFont,
      color: ThemePeazy.colors.textGray,
      fontSize: '0.875rem',
      fontHeight: 600,
      lineHeight: 'normal',
      textTransform: 'uppercase',
    }
  },
  messages: {
    default: {
      borderLeftColor: ThemePeazy.colors.darkBlue,
      bg: ThemePeazy.colors.lightBlue,
    },
  },
  styles: {
    a: {
      color: ThemePeazy.colors.orange,
      cursor: 'pointer',
      ':hover': {
        color: ThemePeazy.colors.orange,
      },
    },
    hr: {
      m: 0,
      color: '#f1f4f8',
      height: '1px',
    },
  },
  forms: {
    input: inputBase,
    inputError: {
      ...inputBase,
      borderColor: 'red',
    },
    inputV2: {
      ...inputBaseV2
    },
    inputV2Error: {
      ...inputBaseV2,
      borderColor: 'red',
    },
    select: {
      display: 'block',
      ...inputBase
    },
    concierge: {
      serviceSelect: {
        borderRadius: '1rem',
        backgroundColor: '#FFF',
        border: '1px solid #FFF',
        px: '1.125rem'
      },
      input: {
        border: `1px solid ${themeJSON.colors.BorderGray}`
      }
    },
    label: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem'
    },
    textarea: {
      ...inputBaseV2,
      resize: 'none',
    }
  },
  alerts: {
    error: {
      bg: '#ff4d4f',
    },
    orange: {
      bg: ThemePeazy.colors.orange,
    },
  },
};

export default theme;
