import React from 'react';
import {
  Location,
  UpdateLocationInput,
  CreateLocationInput,
  Maybe,
  V2Gateway_UpdateLocationsMutationHookResult,
  LocationType,
  CreateLocationMutationHookResult,
  CreateLocationMutationResult,
} from 'Generated/graphql';
import { Box } from 'theme-ui';
import {
  CreateLocationMutationReturn,
  LocationFormValues
} from '.';
import { deleteTypeNameField } from 'Common/functions/Form';
import { FormikConfig, FormikErrors } from 'formik';
import {
  FormChainLinkSubmitFuncReturn,
} from 'Common/context/FormChainContextProvider';
import { submitData } from 'Common/functions/FormChain/submit';
import * as yup from 'yup';
import { message } from 'antd';
import PlaceIdField from './field/PlaceIdField';
import TypeField from './field/TypeField';
import PhoneNumberField from './field/PhoneNumberField';
import NameField from './field/NameField';
import UidField from './field/UidField';
import ExtendedAddressField from './field/ExtendedAddressField';
import {
  ManageLocationsLocation
} from '../ManageLocations/LocationListItem/ManageLocation';

export enum LocationModFieldKey {
  Type = '0',
  Uid = '1',
  Name = '2',
  PlaceId = '3',
  ExtendedAddress = '4',
  PhoneNumber = '5',
}

type ModVal = keyof CreateLocationInput | keyof UpdateLocationInput

export type ExistingLocation = Pick<
  Location,
  'uid' | 'placeId' | 'name' | 'type' | 'phoneNumber' | 'externalUid' |
  'formattedAddress'
>

const LocationModFields: Record<
  LocationModFieldKey,
  ModVal
> = {
  [LocationModFieldKey.Uid]: 'uid',
  [LocationModFieldKey.Name]: 'name',
  [LocationModFieldKey.Type]: 'type',
  [LocationModFieldKey.PlaceId]: 'placeId',
  [LocationModFieldKey.ExtendedAddress]: 'extendedAddress',
  [LocationModFieldKey.PhoneNumber]: 'phoneNumber',
};

const getUidField = (
  ff: ModVal,
): React.ReactNode => {
  return <UidField key={ff} name={ff} />;
};

const getNameField = (
  ff: ModVal,
): React.ReactNode => {
  return <NameField key={ff} name={ff} />;
};

const getPhoneNumberField = (
  ff: ModVal,
): React.ReactNode => {
  return <PhoneNumberField key={ff} name={ff} />;
};

const getTypeField = (
  ff: ModVal,
  hideTypeOptions?: LocationType[],
): React.ReactNode => {
  return <TypeField key={ff} name={ff} hideOptions={hideTypeOptions} />;
};

const getPlaceIdField = (
  ff: ModVal,
  defaultAddress?: string,
): React.ReactNode => {
  return (
    <PlaceIdField key={ff} name={ff} defaultAddress={defaultAddress} />
  );
};

const getExtendedAddressField = (
  ff: ModVal,
): React.ReactNode => {
  return (
    <ExtendedAddressField key={ff} name={ff} />
  );
};

const getCreateModFormItems = (
  hideFields: LocationModFieldKey[],
  defaultAddress?: string,
  hideTypeOptions?: LocationType[],
) => {
  const allItems = Object.entries(LocationModFields).map(
    ([k, ff]) => {
      // hard-cast because Object.entries typing sucks
      const ffk = k as unknown as LocationModFieldKey;
      const isHidden = hideFields.includes(ffk);
      const el = (() => {
        switch (ffk) {
          case LocationModFieldKey.Name:
            return getNameField(ff);
          case LocationModFieldKey.PhoneNumber:
            return getPhoneNumberField(ff);
          case LocationModFieldKey.Type:
            return getTypeField(ff, hideTypeOptions);
          case LocationModFieldKey.PlaceId:
            return getPlaceIdField(ff, defaultAddress);
          case LocationModFieldKey.ExtendedAddress:
            return getExtendedAddressField(ff);
        }
      })();
      return (
        <Box key={ff} sx={isHidden ? { display: 'none' } : undefined}>
          {el}
        </Box>
      );
    });

  return allItems;
};

const getUpdateModFormItems = (
  hideFields: LocationModFieldKey[],
  defaultAddress?: string,
  hideTypeOptions?: LocationType[],
) => {
  const allItems = Object.entries(LocationModFields).map(
    ([k, ff]) => {
      // hard-cast because Object.entries typing sucks
      const ffk = k as unknown as LocationModFieldKey;
      if (!hideFields.includes(ffk)) {
        switch (ffk) {
          case LocationModFieldKey.Uid:
            return getUidField(ff);
          case LocationModFieldKey.Name:
            return getNameField(ff);
          case LocationModFieldKey.PhoneNumber:
            return getPhoneNumberField(ff);
          case LocationModFieldKey.Type:
            return getTypeField(ff, hideTypeOptions);
          case LocationModFieldKey.PlaceId:
            return getPlaceIdField(ff, defaultAddress);
          case LocationModFieldKey.ExtendedAddress:
            return getExtendedAddressField(ff);
        }
      }
    });

  return allItems;
};

export const getFormItems = (
  hideFields: LocationModFieldKey[],
  existingMod?: ExistingLocation | undefined,
  hideTypeOptions?: LocationType[],
  defaultAddress?: string,
) => {
  if (existingMod) {
    return getUpdateModFormItems(hideFields, defaultAddress, hideTypeOptions);
  }

  return getCreateModFormItems(hideFields, defaultAddress, hideTypeOptions);
};

export const getInitialValueFromFocusedMod = (
  focusedMod: ExistingLocation,
): LocationFormValues => {
  const b = deleteTypeNameField<any>(focusedMod);

  return {
    type: b.type,
    name: b.name,
    phoneNumber: b.phoneNumber,
    uid: b.uid,
    placeId: b.placeId,
    extendedAddress: b.extendedAddress,
  };
};

export const getLocationFormInitialValue = (
  focusedMod: Maybe<ExistingLocation>,
): Partial<LocationFormValues> => {
  if (focusedMod) {
    return getInitialValueFromFocusedMod(focusedMod);
  }

  return {};
};

type createdLocation = NonNullable<
  CreateLocationMutationResult['data']
>['createLocation']['location']

type createFn = (data: CreateLocationInput) => Promise<
  FormChainLinkSubmitFuncReturn<{
    location: createdLocation,
  }>
>
export const getLocationFormCreateFn = (
  doCreate: CreateLocationMutationHookResult[0],
): createFn => async (data: CreateLocationInput): Promise<
  FormChainLinkSubmitFuncReturn<{
    location: createdLocation,
  }>
> => {
  if (data) {
    const res = await doCreate({
      variables: {
        data,
      }
    });

    if (res.data?.createLocation) {
      return [[{
        location: res.data.createLocation.location,
      }], true];
    }

    message.warning('Failed to create locations');

    return [null, false];
  }

  return [null, true];
};

type updateFn = (data: UpdateLocationInput[]) => Promise<
  FormChainLinkSubmitFuncReturn<{
    internalUids: string[],
  }>
>
export const getLocationFormUpdateFn = (
  doUpdate: V2Gateway_UpdateLocationsMutationHookResult[0],
): updateFn => async (data: UpdateLocationInput[]): Promise<
  FormChainLinkSubmitFuncReturn<{
    internalUids: string[],
  }>
> => {
  if (data.length) {
    const res = await doUpdate({
      variables: {
        data,
      }
    });

    if (res.data?.updateLocations) {
      return [[{ internalUids: data.map(d => d.uid) }], true];
    }

    message.warning('Failed to update locations');

    return [null, false];
  }

  return [null, true];
};

export const getLocationFormSubmitFn = (
  createFn: createFn,
  updateFn: updateFn,
) => async (data: LocationFormValues) => {
  const res = await submitData<
    CreateLocationInput,
    UpdateLocationInput,
    { location: createdLocation },
    CreateLocationMutationReturn
  >(
    [data],
    d => createFn(d[0]),
    d => updateFn(d),
  );

  return res;
};

const commonLocationFormValidationSchema = {
  name: yup.string(),
  type: yup.string().required(),
  phoneNumber: yup.string(),
  placeId: yup.string().required(),
  extendedAddress: yup.string().optional(),
};

export const createLocationFormValidationSchema = yup.object(
  commonLocationFormValidationSchema,
);

export const updateLocationFormValidationSchema = yup.object({
  uid: yup.string(),
}).shape(commonLocationFormValidationSchema);

export const getLocationFormFormikConfig = (
  focusedMod: Maybe<Omit<ManageLocationsLocation, 'serviceAreas'>>,
  submitForm: (values: LocationFormValues) => Promise<unknown>,
) => {
  const initialValues =
    getLocationFormInitialValue(focusedMod);
  const formikCfg: FormikConfig<
    Partial<LocationFormValues>
  > = {
    initialValues,
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (
      values,
      { resetForm },
    ) => {
      submitForm(values as LocationFormValues).then(() => {
        resetForm();
      });
    },
    validationSchema: focusedMod ?
      updateLocationFormValidationSchema :
      createLocationFormValidationSchema,
  };

  return formikCfg;
};

type submitFn = (v: LocationFormValues) => Promise<
  FormChainLinkSubmitFuncReturn<CreateLocationMutationReturn | { location: createdLocation }>
>

export const doSubmitLocationForm = (
  validateForm: () => Promise<FormikErrors<LocationFormValues>>,
  submitFn: submitFn,
  values: LocationFormValues,
  onBeforeSubmit?: (next: () => void) => void,
) => {
  return validateForm().then(async errors => {
    if (Object.keys(errors).length > 0) {
      console.warn(errors);
    } else {
      if (onBeforeSubmit) {
        await new Promise(res => {
          onBeforeSubmit(() => submitFn(values).then(res));
        });
      } else {
        await submitFn(values);
      }
    }
  });
};

