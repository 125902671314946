import { Field, FieldProps } from 'formik';
import { CreateLocationInput } from 'Generated/graphql';
import { Input, Label, Text } from 'theme-ui';
import theme from 'Common/theme';

type NameFieldProps = {
  name: string;
}

const NameField: React.FC<NameFieldProps> = ({
  name,
}) => {
  const fieldFn =
    ({
      form,
      field,
      meta,
    }: FieldProps<CreateLocationInput['name']>) => {
      return (
        <Label
          key={name}
        >
          Name
          <Input
            sx={meta.error ?
              theme.forms?.inputError :
              theme.forms?.input}
            defaultValue={field.value}
            onChange={e => form.setFieldValue(name, e.target.value)}
          />
          {meta.error && (
            <Text as="small" variant="text.error">
              {meta.error}
            </Text>
          )}
        </Label>
      );
    };

  return (
    <>
      <Field
        label=""
        name={name}
      >
        {fieldFn}
      </Field>
    </>
  );
};

export default NameField;


