import { Text } from 'theme-ui';

export const getErrorMessage = (err: string | undefined) => Boolean(err) && (
  <Text as="small" variant="text.error">
    {err}
  </Text>
);

export const deleteTypeNameField = <
  T extends Partial<Record<'__typename', string>> = Partial<
    Record<'__typename', string>
  >
>(data: T): Omit<T, '__typename'> => {
  const d = { ...data };
  if (d.__typename) {
    delete (
      d as Partial<Pick<T, '__typename'>> & Omit<T, '__typename'>
    ).__typename;
  }
  return d;
};

export const deleteTypeNameFields = <
  T extends Partial<Record<'__typename', string>>[] = Partial<
    Record<'__typename', string>
  >[]
>(data: T): Omit<T[number], '__typename'>[] => {
  if (data.length === 0) {
    return [];
  }

  const head = data[0];
  const tail = data.slice(1);
  const chunk = deleteTypeNameFields(tail);

  return [deleteTypeNameField(head), ...chunk];
};
