import { Field, FieldProps } from 'formik';
import { CreateLocationInput, LocationType } from 'Generated/graphql';
import { Label, Text } from 'theme-ui';
import LocationTypeSelect from '../LocationTypeSelect';

type TypeFieldProps = {
  name: string;
  hideOptions?: LocationType[];
}

const TypeField: React.FC<TypeFieldProps> = ({
  name,
  hideOptions,
}) => {
  const fieldFn =
    ({
      form,
      field,
      meta,
    }: FieldProps<CreateLocationInput['type']>) => {
      return (
        <>
          <Label
            style={field.value === LocationType.ServiceProvider ? {
              display: 'none',
            } : {}}
            key={name}
          >
            Type
          </Label>
          <LocationTypeSelect
            value={field.value}
            initialValue={field.value}
            onSelect={t => {
              form.setFieldValue(name, t);
            }}
            hideOptions={hideOptions}
          />
          {meta.error && (
            <Text as="small" variant="text.error">
              {meta.error}
            </Text>
          )}
        </>
      );
    };

  return (
    <>
      <Field
        label=""
        name={name}
        validate={(v: string) => !v ?
          'Please select a type for the location.' :
          undefined}
      >
        {fieldFn}
      </Field>
    </>
  );
};

export default TypeField;

