import {
  FormChainLinkSubmitFuncReturn,
} from 'Common/context/FormChainContextProvider';

export const submitData = async <
  C extends Record<string, unknown>,
  U extends Record<string, unknown> | null,
  R extends Record<string, unknown> = Record<string, unknown>,
  UR extends Record<string, unknown> = Record<string, unknown>,
>(
  data: (C | U)[],
  create: (data: C[]) => Promise<FormChainLinkSubmitFuncReturn<R>>,
  update: ((data: U[]) => Promise<FormChainLinkSubmitFuncReturn<UR>>) | null,
): Promise<FormChainLinkSubmitFuncReturn<R | UR>> => {
  const createData = data.filter(
    d => !(d as C).uid
  ) as C[];
  const updateData = data.filter(
    d => (d as U)?.uid
  ) as U[];

  const createRes = createData.length ?
    await create(createData) :
    null;
  if (createRes !== null && createRes[1]) {
    return createRes;
  }

  const updateRes = updateData.length && update ?
    await update(updateData) :
    null;
  if (updateRes !== null && updateRes[1]) {
    return updateRes;
  }

  return [[], true];
};
